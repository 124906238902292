import i18n from "i18next";
import { initReactI18next } from "react-i18next";
import LanguageDetector from "i18next-browser-languagedetector";


const loadLanguage = async (lng) => {
    try {
        const response = await fetch(`/locales/${lng}.json`);
        return await response.json();
    } catch (error) {
        console.error(`Failed to load ${lng} language file`, error);
        return {}; // 에러 발생 시 빈 객체 반환
    }
};


const initI18n = async () => {
    const defaultLang = localStorage.getItem("language") || "ko_KR";
    const resources = {};

    resources[defaultLang] = {
        translation: await loadLanguage(defaultLang),
    };

    i18n
        .use(LanguageDetector)
        .use(initReactI18next)
        .init({
            fallbackLng: "ko_KR",
            debug: true,
            detection: { order: ["path", "navigator"] },
            resources,
        });
};

initI18n();

export default i18n;
