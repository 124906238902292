import React from 'react';
import '../styles/Loading.css';

const Loading = () => {
    return (
        <div id='loading'>
            <span class="loader-38"></span>
            <h2>Loading</h2>
            <h3>Hold on! 데이터를 불러오고 있습니다</h3>
        </div>
    );
}

export default Loading;