import React from 'react';
// import Modal from 'react-modal';
import '../AddonsPage.css';
import qr from '../../../assets/image/qrcode_169361029_4c2e0c04502259995ef41f48a175da51.svg';

// Modal.setAppElement('#root'); // 접근성 문제 방지

const AddCustomAddon = ({ isOpen, onClose }) => {
    return (
        <div
            isOpen={isOpen}
            onRequestClose={onClose}
            contentLabel="Add Custom Addon"
            className="modal-content"
            overlayClassName="modal-overlay"
        >
            <div>
                <div className="modal-header">
                    <h2>애드온 게시 안내</h2>
                    <button className="close-button" onClick={onClose}>✖</button>
                </div>
                <div className="modal-body">
                    <img src={qr} alt="QR Code" />
                    <p>카카오톡 오픈 채팅에서 애드온 등록을 요청하세요!</p>
                    QR코드 인식 혹은 <a href="https://open.kakao.com/me/ma_ca19" target="_blank" rel="noopener noreferrer">
                        채팅방으로 이동
                    </a>
                </div>
            </div>
        </div>
    );
};

export default AddCustomAddon;
