import axios from 'axios';


export const ip = 'https://mows.mows.kr'

const setToCache = (url, data) => {
    const cacheData = {
        data,
        timestamp: Date.now(),  // 캐시된 시간 저장
    };
    localStorage.setItem(url, JSON.stringify(cacheData));  // 캐시 저장
};

// 캐시된 데이터를 확인하고, 없으면 요청을 보내는 함수
const cachedRequest = async (url, pass = false) => {
    if (pass) return (await axios.get(`${ip}/api${url}`)).data;
    let cachedData;
    const cached = localStorage.getItem(url);
    console.warn('cached = ' + cached)
    if (cached != null) {
        const { data, timestamp } = JSON.parse(cached);
        const CACHE_EXPIRY_TIME = 1 * 60 * 1000;
        if (Date.now() - timestamp < CACHE_EXPIRY_TIME) {
            cachedData = data;
            console.log('캐시에서 데이터 반환:', cachedData);
            return Promise.resolve(cachedData);
        }
        else {
            const response = await axios.get(`${ip}/api${url}`);
            setToCache(url, response.data);  // 서버 응답 데이터를 캐시에 저장
            return response.data;
        }
    }
    else {
        const response = await axios.get(`${ip}/api${url}`);
        setToCache(url, response.data);  // 서버 응답 데이터를 캐시에 저장
        return response.data;
    }
};

// API 요청 함수들
export const fetchAddons = async (code_name = '') => {
    try {
        const data = await cachedRequest(`/addons/${code_name}`, code_name);
        console.warn(data)
        return data;
    } catch (error) {
        if (code_name !== '') {
            return 404;  // 존재하지 않음.
        }
        else {
            return -1; // 서버 응답 없음.
        }
    }
};

export const isExisting = async (code_name) => {
    try {
        const data = await cachedRequest(`/addons/isexist/${code_name}`, true);
        return data['response'];
    } catch (error) {
        return -1;
    }
};

export const fetchAddonImages = async (code_name) => {
    console.log('Trying to fetch image');
    try {
        const data = await cachedRequest(`/addons/images/${code_name}`, false);
        return data;
    } catch (error) {
        return -1;
    }
};