import React, { useState } from "react";
import axios from "axios";
import { ip } from "../../../api";
import '../AddonDetailPage.css';
import DOMPurify from 'dompurify';
import FocusLock from "react-focus-lock";
import VersionSelectModal from "./VersionSelectModal";


const DownloadButton = ({ addon, code_name, t }) => {
    const [lastDownloadTime, setLastDownloadTime] = useState(0);
    const [isModalOpen, setIsModalOpen] = useState(false);

    const handleSubmit = async (event) => {
        console.log('다운로드 로직');
        event.preventDefault();

        try {
            const currentTime = Date.now();
            if (currentTime - lastDownloadTime < 3000) {
                alert(`${((3000 - currentTime + lastDownloadTime) / 1000).toFixed(1)} 초 후 다시 시도해보세요.`);
                return;
            }
            if (!document.getElementById('download').term_agree_button.checked) {
                alert(t('ui_term_agree'));
                return;
            }
            setLastDownloadTime(currentTime);
            const response = await axios.post(`${ip}/api/addons/download/`, { code_name: code_name, version: addon.instances.at(-1).version }, {
                responseType: 'blob',
            });
            const contentDisposition = response.headers["content-disposition"];
            let filename = "default_filename.zip"; // 기본 파일 이름
            if (contentDisposition) {
                const filenameMatch = contentDisposition.match(/filename\*?=(?:UTF-8'')?(.+)/);
                if (filenameMatch) {
                    // 인코딩된 파일 이름 디코딩
                    filename = decodeURIComponent(filenameMatch[1].replace(/(^"|"$)/g, ""));
                }
            }


            // 파일 다운로드
            const url = window.URL.createObjectURL(new Blob([response.data], { type: response.headers['content-type'] }));
            const link = document.createElement('a');
            link.href = url;
            link.setAttribute('download', filename); // 파일 이름 지정
            document.body.appendChild(link);
            link.click();
            link.parentNode.removeChild(link);

            const download_count = document.getElementById('download_count');
            download_count.innerHTML = parseInt(download_count.innerHTML) + 1;
        } catch (error) {
            console.error('Error downloading file:', error);
        }
    };

    const handleVersionSelect = () => {
        setIsModalOpen(true); // 모달 열기
    };

    const closeModal = () => {
        setIsModalOpen(false); // 모달 닫기
    };

    return (
        <div>
            <form id="download" onSubmit={handleSubmit}>
                <div id='term_agree'>
                    <input type="checkbox" id="term_agree_button" name="term_agree_button" />
                    <label htmlFor="term_agree_button" dangerouslySetInnerHTML={{ __html: DOMPurify.sanitize(t('AddonDetailPage-DoanloadButton-term_agree_button')) }}></label>
                </div>
                <div id="down_and_sel">
                    <button type="submit" id='download_button'>
                        <span className="material-symbols-rounded">
                            download
                        </span>
                        {t('download')}
                    </button>
                    <button type="button" id="versions_button" onClick={handleVersionSelect}>
                        <span className="material-symbols-rounded">
                            history_2
                        </span>
                        {t('AddonDetailPage-Modal-title')}
                    </button>
                </div>
            </form>
            {isModalOpen && (
                <FocusLock>
                    <VersionSelectModal addon_instances={addon.instances} onClose={closeModal} code_name={code_name} t={t} />
                </FocusLock>
            )}
        </div>
    );
};

export default DownloadButton;