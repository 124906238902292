import React from "react";
import { Link } from "react-router-dom";
import { useTranslation } from "react-i18next"
import '../HomePage.css'
import '../../common/styles/main.css'

const HomeMenu = () => {
    const { t } = useTranslation();

    return (
        <section className="sections" id='menu'>
            <h2>{t('HomePage_MainContent_menu')}</h2>
            <div>
                <Link to={'addons'}>
                    <span translate='no' className="material-symbols-outlined">
                        download
                    </span>
                    <h3>{t('HomePage_MainContent_menu_addon_title')}</h3>
                    <h4>{t('HomePage_MainContent_menu_addon_description')}</h4>
                </Link>
                <a href="https://www.notion.so/16a986e2790f803bb312ccfce34218f9?v=16a986e2790f81f3984d000ce830d0a0">
                    <span translate='no' className="material-symbols-outlined">
                        description
                    </span>
                    <h3>{t('HomePage_MainContent_menu_docs_title')}</h3>
                    <h4>{t('HomePage_MainContent_menu_docs_description')}</h4>
                </a>
                <Link>
                    <span translate='no' className="material-symbols-outlined">
                        handyman
                    </span>
                    <h3>{t('HomePage_MainContent_menu_tools_title')}</h3>
                    <h4>{t('HomePage_MainContent_menu_tools_description')}</h4>
                </Link>
            </div>
        </section>
    )
};

export default HomeMenu;