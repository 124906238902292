/*
    #LangSelectMenu
    #언어선택 메뉴
    2024-10-25
*/

import React, { useState, useEffect } from 'react';
import i18n from "i18next";

const lang = { 'ko_KR': '한국어', 'en_US': 'ENGLISH' };

const LangSelectMenu = () => {
    const [selectedLang, setSelectedLang] = useState('ko_KR');

    useEffect(() => {
        const storedLang = localStorage.getItem('lang');
        console.warn("Stored Lang:", storedLang);

        if (storedLang) {
            loadLanguage(storedLang);
        } else {
            loadLanguage('ko_KR');
        }
    }, []);

    const loadLanguage = async (lng) => {
        try {
            const response = await fetch(`/locales/${lng}.json`);
            const data = await response.json();
            i18n.addResourceBundle(lng, "translation", data, true, true);
            i18n.changeLanguage(lng);
            setSelectedLang(lng);
        } catch (error) {
            console.error(`Failed to load language file: ${lng}`, error);
        }
    };

    const changeLanguage = async (event) => {
        const newLang = event.target.value;
        console.warn("Changing Language to:", newLang);
        localStorage.setItem('lang', newLang);
        await loadLanguage(newLang);
    };

    return (
        <select id='langsel' onChange={changeLanguage} value={selectedLang}>
            {Object.keys(lang).map((key) => (
                <option key={key} value={key} translate='no'>
                    {lang[key]}
                </option>
            ))}
        </select>
    );
};

export default LangSelectMenu;
