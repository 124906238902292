import React from "react";
import '../../common/styles/main.css';
import addon_icon_alt from './addon_icon_alt.jpeg';
import '../AddonDetailPage.css'
import { ip } from "../../../api";

const PageTitle = ({ addon_name, addon_img, code_name, t }) => {
    const imgURL = addon_img ? `${ip}${addon_img}` : addon_icon_alt;
    return (
        <header>
            <img src={imgURL} alt="null" />
            <div>
                <div id="main">
                    <h1>{addon_name ? addon_name : '-'}</h1>
                    <h2>{t(`addon_description-${code_name}`).split(/<br>/)[0] == `addon_description-${code_name}` ? '' : t(`addon_description-${code_name}`).split(/<br>/)[0]}</h2>
                </div>
                <div id="sub">
                    <a href="#download_button">{t('download')}</a>
                    <a href={`https://docs.mows.kr/${addon_name}`}>{t('manual')}</a>
                </div>
            </div>
        </header>
    )
}

export default PageTitle;