import React, { useState, useEffect } from 'react';
import FocusLock from "react-focus-lock";
import PageTitle from './components/PageTitle';
import SortMenu from './components/SortMenu';
import AddonButton from './components/AddonButton';
import Skeleton from './components/SkeletonButton';
import './AddonsPage.css';
import { fetchAddons } from '../../api';
import NoServerResponsePage from '../error/no server response';
import { useTranslation } from 'react-i18next';
import AddCustomAddon from './components/AddCustomAddon'; // 모달 추가

const AddonsPage = () => {
    const [addons, setAddons] = useState(null);
    const [filteredAddons, setFilteredAddons] = useState([]);
    const [selectedCategories, setSelectedCategories] = useState([]);
    const [sortCriterion, setSortCriterion] = useState('name');
    const [isModalOpen, setIsModalOpen] = useState(false); // 모달 상태 추가
    const { t } = useTranslation();

    useEffect(() => {
        fetchAddons().then(data => {
            console.log(data);
            if (data === -1) {
                setAddons('NO-SERVER-RESPONSE');
            } else {
                setAddons(data);
                setFilteredAddons(data);
            }
        });
    }, []);

    useEffect(() => {
        if (addons && addons !== 'NO-SERVER-RESPONSE') {
            let sortedAddons = [...addons];
            if (sortCriterion === 'name') {
                sortedAddons.sort((a, b) => a.code_name.localeCompare(b.code_name));
            } else if (sortCriterion === 'downloads') {
                sortedAddons.sort((a, b) => b.download_count - a.download_count);
            } else if (sortCriterion === 'update_date') {
                sortedAddons.sort((a, b) => new Date(b.latest_version_date) - new Date(a.latest_version_date));
            }

            if (selectedCategories.length > 0) {
                sortedAddons = sortedAddons.filter(addon =>
                    selectedCategories.includes(addon.category)
                );
            }
            setFilteredAddons(sortedAddons);
        }
    }, [sortCriterion, selectedCategories, addons]);

    const handleSortChange = (criterion) => {
        setSortCriterion(criterion);
    };

    const handleCategoryChange = (categories) => {
        setSelectedCategories(categories);
    };

    if (!addons) {
        return (
            <main className="addons-page">
                <div className='main_content'>
                    <PageTitle title={'Download'} />
                    <section>
                        <div className='skeleton-sort' />
                        <div className="addons-list">
                            <Skeleton />
                            <Skeleton />
                            <Skeleton />
                            <Skeleton />
                        </div>
                    </section>
                </div>
            </main>
        );
    } else if (addons === 'NO-SERVER-RESPONSE') {
        return <NoServerResponsePage />;
    } else {
        return (
            <main className='addons-page'>
                <div className='main_content'>
                    <PageTitle title={'Download'} />
                    <section>
                        <SortMenu onSortChange={handleSortChange} onCategoryChange={handleCategoryChange} />
                        <div className="addons-list">
                            {filteredAddons.map(i => (
                                <AddonButton addon={i} t={t} key={i.id} />
                            ))}
                            <a href="#" onClick={(e) => { e.preventDefault(); setIsModalOpen(true); }} title={'나만의 애드온 추가하기'} className="addon-button">
                                <div id='main'>
                                    <span class="material-symbols-rounded">
                                        add
                                    </span>
                                    <h3>나만의 애드온 추가하기</h3>
                                    <h4>{ }</h4>
                                </div>
                            </a>
                        </div>
                    </section>
                </div >
                {
                    isModalOpen && (
                        <FocusLock>
                            <AddCustomAddon isOpen={isModalOpen} onClose={() => setIsModalOpen(false)} />
                        </FocusLock>
                    )
                }


            </main >
        );
    }
};

export default AddonsPage;
